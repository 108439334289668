<template>
  <div class="vat-box">
    <div class="billing">
      <div class="billing-label col-12 pa-0 mb-2">
        <span v-if="previewOrder.payPeriod === 12">
          {{ $t('billwerk.customer-form.preview-order.annual-billing') }}
        </span>
        <span v-else>{{ $t('billwerk.customer-form.preview-order.monthly-billing') }}</span>
      </div>
      <div
        v-for="(period, index) of previewOrder.billingPeriods"
        :key="period.Id"
        class="col-12 pa-0 mb-2"
      >
        <div class="d-flex justify-space-between mb-1">
          {{ period.Description }}
        </div>
        <div class="d-flex justify-space-between mb-1">
          <div class="">
            {{ formatPrice(period.TotalNet / getPayPeriod(period.PeriodMultiplier), previewOrder.currency, 2) }} x {{ getPayPeriod(period.PeriodMultiplier) }}
            <span v-if="getPayPeriod(period.PeriodMultiplier) === 1">
              {{ $t('billwerk.customer-form.preview-order.month') }}
            </span>
            <span v-else>{{ $t('billwerk.customer-form.preview-order.months') }}</span>
          </div>
          <div class="billing-amount">
            {{ formatPrice(period.TotalNet, previewOrder.currency, 2) }}
          </div>
        </div>

        <div class="d-flex justify-space-between mb-1">
          <div class="">
            + {{ $t('billwerk.customer-form.preview-order.tax-label') }}
          </div>
          <div class="billing-vat">
            {{ formatPrice(period.TotalVat, previewOrder.currency, 2) }}
          </div>
        </div>
        <v-divider
          v-if="index !== previewOrder.billingPeriods.length - 1"
          :style="vatBoxDivider"
          class="col-12 mb-2 pa-0 vat-box-divider"
        />
      </div>
    </div>
    <v-divider
      :style="vatBoxDivider"
      class="col-12 mb-2 pa-0 vat-box-divider"
    />
    <div
      v-if="previewOrder.coupon && previewOrder.isCouponValid"
      class="coupons"
    >
      <div class="col-12 coupon-label pa-0">
        {{ $t('billwerk.customer-form.preview-order.coupon-label') }}
      </div>
      <div
        class="d-flex justify-space-between mb-1"
      >
        <div>
          "{{ previewOrder.coupon.CouponCode }}"
        </div>
        <div class="billing-vat">
          {{ previewOrder.coupon.Description }}
        </div>
      </div>
    </div>
    <v-divider
      v-if="previewOrder.coupon && previewOrder.isCouponValid"
      :style="vatBoxDivider"
      class="col-12 mb-2 pa-0 vat-box-divider"
    />
    <div class="total d-flex justify-space-between">
      <div class="total-label">
        {{ $t('billwerk.customer-form.preview-order.total-price-label') }}
      </div>
      <div class="total-amount">
        {{ formatPrice(previewOrder.totalAfterVat, previewOrder.currency, 2) }}
      </div>
    </div>
  </div>
</template>
<script>
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
import currencyMixin from '@/mixins/currency'

export default {
  mixins: [currencyMixin],
  props: {
    previewOrder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      vatBoxDivider: {
        color: this.$vuetify.theme.themes.light.primary
      }
    }
  },
  computed: {
    productPackage () {
      return getProductPackageQuery()
    },
    isYearlyPaying () {
      return this.productPackage.isYearly
    }
  },
  methods: {
    getPayPeriod (periodMultiplier) {
      return this.previewOrder.payPeriod * periodMultiplier
    }
  }
}
</script>

<style scoped>
.vat-box {
  font-size: 16px;
}

.total, .billing-label {
  font-size: 18px;
}

.billing-label, .coupon-label, .billing-amount, .billing-vat, .total-amount, .total-label {
  font-weight: bold;
}

</style>
